exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-alpex-exim-js": () => import("./../../../src/pages/portfolio/alpex-exim.js" /* webpackChunkName: "component---src-pages-portfolio-alpex-exim-js" */),
  "component---src-pages-portfolio-bi-js": () => import("./../../../src/pages/portfolio/bi.js" /* webpackChunkName: "component---src-pages-portfolio-bi-js" */),
  "component---src-pages-portfolio-bmc-js": () => import("./../../../src/pages/portfolio/bmc.js" /* webpackChunkName: "component---src-pages-portfolio-bmc-js" */),
  "component---src-pages-portfolio-eclipse-js": () => import("./../../../src/pages/portfolio/eclipse.js" /* webpackChunkName: "component---src-pages-portfolio-eclipse-js" */),
  "component---src-pages-portfolio-jaycee-js": () => import("./../../../src/pages/portfolio/jaycee.js" /* webpackChunkName: "component---src-pages-portfolio-jaycee-js" */),
  "component---src-pages-portfolio-weconnect-js": () => import("./../../../src/pages/portfolio/weconnect.js" /* webpackChunkName: "component---src-pages-portfolio-weconnect-js" */),
  "component---src-pages-portfolio-winpe-js": () => import("./../../../src/pages/portfolio/winpe.js" /* webpackChunkName: "component---src-pages-portfolio-winpe-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-refund-cancellation-js": () => import("./../../../src/pages/refund-cancellation.js" /* webpackChunkName: "component---src-pages-refund-cancellation-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-studio-booking-js": () => import("./../../../src/pages/studio-booking.js" /* webpackChunkName: "component---src-pages-studio-booking-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-vo-directory-js": () => import("./../../../src/pages/vo-directory.js" /* webpackChunkName: "component---src-pages-vo-directory-js" */)
}

