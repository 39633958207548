import "./src/styles/global.style.css";

export const onInitialClientRender = () => {
  const loader = document.getElementById("loader");
  const body = document.querySelector(".layout__content");
  body.classList.add("loader-open");
  loader.classList.add("loader--active");
  setTimeout(function () {
    body.classList.remove("loader-open");
    loader.classList.remove("loader--active");
  }, 2000);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const body = document.querySelector(".layout__content");
  if (body) {
    body.classList.remove("loader-open");
  }
};
